import { proxied } from 'radash';
import { convertVndToVnd1000 } from '@packages/currency';
import { CurrencyEnum, DEFAULT_CURRENCY } from '~constants/etc';

export const convertCurrency = (
  balance: number | undefined,
  currency: CurrencyEnum | string | undefined,
): number => {
  const currentBalance = balance || 0;
  const balanceWithCurrency = proxied((prop) => {
    switch (prop) {
      case CurrencyEnum.VND:
        return Number(convertVndToVnd1000(currentBalance));
      case CurrencyEnum.THB:
        return currentBalance;
      default:
        return 0;
    }
  });

  return balanceWithCurrency[currency || DEFAULT_CURRENCY];
};
