import {
  ChangeWalletOutlined,
  CorrectOutlined,
  DownArrowFilled,
  PersonFilled,
  WalletOutlined,
} from '@packages/icons-react';
import { cn } from 'class-merge';
import Image from 'next/image';
import numeral from 'numeral';
import type { ReactNode } from 'react';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MoneyFormat } from '~constants/etc';

interface Items {
  name: string;
  className?: string;
  icon?: string | React.ReactNode;
  action: (() => void) | string;
  credit?: number | string;
}

interface DynamicMenuProps {
  items: Items[];
  itemActive: string;
  onChange: (action: string) => void;
  isWallet?: boolean;
  isArrow?: boolean;
  activeClassName?: string;
  className?: string;
  label?: ReactNode;
}

const DynamicMenu: React.FC<DynamicMenuProps> = ({
  items,
  itemActive,
  className,
  onChange,
  isWallet,
  isArrow = true,
  activeClassName = 'bg-evaluated',
  label,
}) => {
  const { t } = useTranslation('common');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = useCallback((): void => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const activeData = items.find((item) => item.action === itemActive);

  const handleItemClick = (action: (() => void) | string): void => {
    if (typeof action === 'function') {
      action();
    } else {
      onChange(action);
    }
    setIsMenuOpen(false);
  };

  return (
    <div className="relative inline-block min-h-[36px] text-left">
      <button
        className={cn(
          'bg-dark flex min-h-9 w-full items-center rounded-md p-1.5 text-white',
          className,
        )}
        onClick={toggleMenu}
        ref={buttonRef}
      >
        {activeData?.icon ? (
          <div
            className={cn(
              isWallet && 'min-w-[100px]',
              'flex w-full items-center gap-x-2',
            )}
          >
            {!isWallet && typeof activeData.icon === 'string' ? (
              <Image
                alt={activeData.name}
                draggable={false}
                height={24}
                src={activeData.icon}
                width={24}
              />
            ) : (
              <div className="flex w-full items-center">
                <WalletOutlined className="text-primary text-base" />
                <div className="w-full text-right text-xs">
                  {numeral(activeData?.credit ?? 0).format(MoneyFormat)}
                </div>
              </div>
            )}
            {isArrow ? <DownArrowFilled className="text-base" /> : null}
          </div>
        ) : (
          <PersonFilled className="text-2xl text-white" />
        )}
        {label ? label : null}
      </button>

      <div
        className={cn(
          isMenuOpen
            ? 'visible scale-100 opacity-100'
            : 'invisible scale-0 opacity-0',
          'transform transition-all duration-300',
          'bg-secondary absolute right-0 z-20 mt-2.5 w-max space-y-2.5 rounded-2xl p-2.5 text-white shadow-lg md:mt-5',
        )}
        ref={menuRef}
      >
        {isWallet ? (
          <div className="mb-1 flex items-center gap-x-1 text-sm">
            <ChangeWalletOutlined className="text-success text-base" />
            <span>{t('wallet')}</span>
          </div>
        ) : null}

        {items.map((item) => {
          const isActive = item.action === itemActive;
          return (
            <button
              className={cn(
                isActive && activeClassName,
                'hover:bg-evaluated flex w-full items-center space-x-2 rounded-2xl px-4 py-2 text-left',
              )}
              key={item.name}
              onClick={() => {
                handleItemClick(item.action);
              }}
            >
              {item.icon && typeof item.icon === 'string' ? (
                <Image
                  alt={item.name}
                  draggable={false}
                  height={24}
                  src={item.icon}
                  width={24}
                />
              ) : (
                <span
                  className={cn(
                    item.credit ? 'text-white' : 'text-light',
                    'text-2xl',
                  )}
                >
                  {item.icon}
                </span>
              )}
              <div className="flex w-full flex-col">
                <span
                  className={cn(
                    'min-w-24 text-left text-sm capitalize',
                    item.className,
                  )}
                >
                  {item.credit
                    ? numeral(item.credit).format(MoneyFormat)
                    : item.name}
                </span>
                {item.credit ? (
                  <span
                    className={cn(
                      'text-light text-xs',
                      isActive && 'text-white',
                    )}
                  >
                    {item.name}
                  </span>
                ) : null}
              </div>
              {isActive && item.credit ? (
                <div className="bg-success relative h-4 min-w-4 rounded-full">
                  <CorrectOutlined className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[8px]" />
                </div>
              ) : null}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DynamicMenu;
