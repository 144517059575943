import { CheckOutlined, ErrorFilled } from '@packages/icons-react';
import { proxied } from 'radash';
import type { FC, ReactNode } from 'react';
import { Suspense, useMemo } from 'react';
import { Trans } from 'react-i18next';
import type { AnyAbility } from '@casl/ability';
import type { CaslAbilityInterface } from '../../types/wallet';
import AllowedProviderCasinoLists from './allowed-provider-casino-lists';
import AllowedProviderGamingLists from './allowed-provider-gaming-lists';
import AllowedProviderSportLists from './allowed-provider-sport-lists';
import { Button } from '~components/button';
import LoadingText from '~components/loading';
import { useDialog } from '~hooks/use-dialog';
import { ButtonColors } from '~constants/components';
import { useAbilities } from '~contexts/abilities';
import TagsWidget from '~components/widgets/tag-widget';
import { ProviderTypeEnum } from '~constants/etc';
import AllowedProviderLottoLists from '~containers/promotions/allowed-provider-lotto-lists';
import AllowedProviderWidget from '~components/widgets/allowed-provider-widget';

export type AllowedGameProvidersType = CaslAbilityInterface;

interface AllowedProviderDialogProps {
  description?: ReactNode | string;
  btnText?: string;
  allowedProviders: AllowedGameProvidersType[];
}

export interface AllowedProviderType {
  title: ReactNode;
  content: ReactNode;
}

const AllowedProviderMiniGameListsComponents = proxied((provider: string) => {
  switch (provider) {
    case ProviderTypeEnum.Casino:
      return AllowedProviderCasinoLists;
    case ProviderTypeEnum.Gaming:
      return AllowedProviderGamingLists;
    case ProviderTypeEnum.Sportsbook:
      return AllowedProviderSportLists;
    case ProviderTypeEnum.Lotto:
      return AllowedProviderLottoLists;
  }
});

export const getAllowedProvider = (
  ability: AnyAbility,
  allowedProviders: AllowedGameProvidersType[],
): AllowedProviderType[] => {
  return [
    ProviderTypeEnum.Casino,
    ProviderTypeEnum.Gaming,
    ProviderTypeEnum.Sportsbook,
    ProviderTypeEnum.Lotto,
  ].map((provider) => {
    const isAllowedAllProvider = ability?.can('read', provider, '**');
    const RenderProviderListComponent =
      AllowedProviderMiniGameListsComponents[provider];
    return {
      title: <Trans i18nKey={`game-categories:${provider}`} />,
      content: isAllowedAllProvider ? (
        <TagsWidget
          className="w-[78px]"
          logo={
            <div className="bg-success flex h-[16px] w-[16px] items-center justify-center rounded text-[8px] text-white">
              <CheckOutlined />
            </div>
          }
          text={<Trans i18nKey="financials:allowed-text" />}
        />
      ) : (
        <Suspense fallback={<LoadingText />}>
          {RenderProviderListComponent ? (
            <RenderProviderListComponent providers={allowedProviders} />
          ) : null}
        </Suspense>
      ),
    };
  });
};

const AllowedProviderDialog: FC<AllowedProviderDialogProps> = (props) => {
  const {
    allowedProviders = [],
    description = (
      <Trans i18nKey="financials:deposit-allowed-provider-description-default-text" />
    ),
    btnText = <Trans i18nKey="buttons:btn-check-game-lists" />,
  } = props;
  const { ability, updateAbilities } = useAbilities();
  updateAbilities(allowedProviders);
  const [dialog, dialogContext] = useDialog();

  const allowedProviderMapped = useMemo(() => {
    return getAllowedProvider(ability, allowedProviders);
  }, [ability, allowedProviders]);

  const handleOpenDialogProvider = (): void => {
    dialog.content({
      maskable: true,
      className: 'w-full h-fit p-0 rounded flex items-center',
      children: (
        <AllowedProviderWidget
          onClose={() => {
            dialog.destroy();
          }}
          providers={allowedProviderMapped}
        />
      ),
    });
  };
  return (
    <div className="bg-dark flex min-h-[100px] w-full flex-col rounded-3xl border-none p-3">
      {dialogContext}
      <div className="text-color-blue-metal flex justify-between gap-x-2 text-sm font-medium">
        <ErrorFilled className="pt-0.5" />
        <div>{description}</div>
      </div>

      <Button
        className="text-md mt-2.5"
        color={ButtonColors.Default}
        onClick={handleOpenDialogProvider}
        type="button"
      >
        {btnText}
      </Button>
    </div>
  );
};

export default AllowedProviderDialog;
