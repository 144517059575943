import { useMemo } from 'react';
import { CloseOutlined } from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import type { AllowProviderType } from 'types/promotion';
import type { AllowedGameProvidersType } from './allowed-provider-container';
import { DEFAULT_CURRENCY, ProviderTypeEnum } from '~constants/etc';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { useAbilities } from '~contexts/abilities';
import TagsWidget from '~components/widgets/tag-widget';
import { useProviders } from '~hooks/use-providers';
import { ProviderEnum } from '~constants/menus';

interface AllowedProviderCasinoListsProps {
  providers: AllowedGameProvidersType[];
}

const AllowedProviderCasinoLists: React.FC<AllowedProviderCasinoListsProps> = (
  props,
) => {
  const { providers } = props;
  const { t } = useTranslation(['providers', 'financials']);
  const { ability } = useAbilities();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: casinos } = useProviders(
    {
      type: ProviderEnum.CASINO,
      currency,
    },
    { suspense: true },
  );

  const providerLists = useMemo<AllowProviderType[]>(() => {
    let casinoAllowProviders: AllowProviderType[] = [];
    casinos
      ? casinos?.forEach((casino) => {
          const allow = ability?.can(
            'read',
            ProviderTypeEnum.Casino,
            casino.id,
          );
          if (!allow) {
            return;
          }
          casinoAllowProviders = [
            ...casinoAllowProviders,
            {
              providerName: t(`providers:${casino.id}` as any),
              logo: casino.logo,
            },
          ];
        })
      : null;
    if (!casinoAllowProviders.length) {
      return [
        {
          providerName: t('financials:not-allowed-text'),
          logo: (
            <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-red-700 text-[8px] text-white">
              <CloseOutlined />
            </div>
          ),
        },
      ];
    }
    return casinoAllowProviders;
  }, [providers, casinos]);

  return (
    <div className="flex flex-wrap gap-2">
      {providerLists.map((provider, i) => (
        <TagsWidget key={i} logo={provider.logo} text={provider.providerName} />
      ))}
    </div>
  );
};

export default AllowedProviderCasinoLists;
