import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import { Button } from '~components/button';
import { ButtonColors, SizeTypes } from '~constants/components';
import PageUrls from '~constants/page-urls';
import UserMenu from '~components/widgets/user-menu';
import request from '~libs/request';
import HttpErrorHandler from '~components/http-error-handler';
import { useUserInfo } from '~hooks/use-user';
import WalletContainer from '~containers/wallet-container';
import {
  DEFAULT_LANG,
  LanguageEnum,
  WalletWidgetTypeEnum,
} from '~constants/etc';
import ChangeLanguageMenu from '~components/widgets/change-language';

const AuthorizedNavigation: FC = () => {
  const { t } = useTranslation('common');
  const { data: userInfo, mutate: mutateUser, displayName } = useUserInfo();
  const isLanguageThailand = DEFAULT_LANG === LanguageEnum.TH;

  const handleSwitchWallet = async (walletId: string): Promise<void> => {
    try {
      await request({
        url: `/wallets/${walletId}/select`,
        method: 'PUT',
      });
      mutateUser();
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <div className="mt-4 flex h-full w-full flex-col items-center gap-2 sm:mt-0 sm:flex-row">
      <div
        className={cn(
          'w-full items-center justify-center gap-2',
          isLanguageThailand ? 'grid grid-cols-2 sm:flex' : 'flex',
        )}
      >
        <WalletContainer
          activeWalletId={userInfo?.selectedWallet.objectId || ''}
          handleSwitchWallet={handleSwitchWallet}
          type={WalletWidgetTypeEnum.Navigation}
          wallets={userInfo?.wallets}
        />
        {!isLanguageThailand ? (
          <div className="flex gap-2">
            <UserMenu username={displayName} />
            <div className="text-right">
              <ChangeLanguageMenu />
            </div>
          </div>
        ) : (
          <UserMenu username={displayName} />
        )}
      </div>
      <div className="flex w-full items-center gap-1">
        <Button
          block
          className="w-full min-w-max border-opacity-30 px-2 text-sm md:min-w-[100px] md:px-4"
          color={ButtonColors.Primary}
          href={PageUrls.FinancialDeposit}
          size={SizeTypes.Small}
        >
          {t('deposit')}
        </Button>
        <Button
          block
          className="w-full min-w-max border border-white border-opacity-30 px-2 text-sm md:min-w-[100px] md:px-4"
          color={ButtonColors.Transparent}
          href={PageUrls.FinancialWithdraw}
          size={SizeTypes.Small}
        >
          {t('withdraw')}
        </Button>
      </div>
    </div>
  );
};

export default AuthorizedNavigation;
