import { cn } from 'class-merge';
import Image from 'next/image';
import React from 'react';

interface ProviderTagWidgetProps {
  logo?: string | React.ReactNode;
  text?: string | React.ReactNode;
  className?: string;
  classNameLogo?: string;
}

const TagsWidget: React.FC<ProviderTagWidgetProps> = (props) => {
  const { logo, text, className, classNameLogo } = props;

  return (
    <div
      className={cn(
        'bg-purple-dark text-color flex max-h-[32px] min-w-[32px] items-center justify-center gap-2 rounded p-2 text-center text-sm capitalize',
        className,
      )}
    >
      {typeof logo === 'string' ? (
        <div
          className={cn(
            'flex h-[16px] w-[16px] items-center justify-center rounded bg-[#242A3E]',
            classNameLogo,
          )}
        >
          <Image
            alt="provider-logo"
            draggable={false}
            height={16}
            src={logo}
            unoptimized
            width={16}
          />
        </div>
      ) : (
        <div>{logo}</div>
      )}
      {text}
    </div>
  );
};

export default TagsWidget;
