'use client';

import {
  BankOutlined,
  BetTransactionFilled,
  LockRerollOutlined,
  PaperInfoOutlined,
  ProfileFilled,
} from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { destroyCookie } from 'nookies';
import { signOut } from 'next-auth/react';
import DynamicMenu from '~components/widgets/dynamic-menu';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import { useDialog } from '~hooks/use-dialog';

const UserMenu: React.FC<{
  username: string;
}> = ({ username }) => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'navigation', 'etc', 'buttons']);
  const [dialog, dialogContext] = useDialog();

  const handleLogout = (): void => {
    dialog.content({
      maskable: true,
      size: 'xxl',
      children: (
        <div className="bg-secondary w-full rounded-2xl p-5 sm:w-96">
          <div className="mt-5 flex flex-col gap-1.5 text-center">
            <p className="text-base font-bold">{t('etc:logout-description')}</p>
            <p className="text-gray text-sm">{t('etc:confirm-logout')}</p>
          </div>

          <div className="mt-7 flex flex-col gap-2.5">
            <Button
              color={ButtonColors.Danger}
              onClick={async () => {
                destroyCookie(null, 'access_token', { path: '/' });
                await signOut({ callbackUrl: PageUrls.Home });
              }}
            >
              {t('buttons:btn-confirm')}
            </Button>
            <Button
              color={ButtonColors.Transparent}
              onClick={() => {
                dialog.destroy();
              }}
            >
              {t('buttons:btn-cancel')}
            </Button>
          </div>
        </div>
      ),
      onClose: () => {
        dialog.destroy();
      },
    });
  };

  const menuData = [
    {
      name: t('bet-transactions'),
      icon: <BetTransactionFilled />,
      action: PageUrls.BetTransactionCasino,
    },
    {
      name: t('common:profile'),
      icon: <ProfileFilled />,
      action: PageUrls.Account,
    },
    {
      name: t('common:bank-account'),
      icon: <BankOutlined />,
      action: PageUrls.PaymentMethods,
    },
    {
      name: t('common:financial-transaction'),
      icon: <PaperInfoOutlined />,
      action: PageUrls.FinancialTransaction,
    },
    {
      name: t('common:change-password'),
      icon: <LockRerollOutlined />,
      action: PageUrls.ChangePassword,
    },
    {
      name: t('navigation:logout-title'),
      action: handleLogout,
      className: 'text-center text-danger',
    },
  ];

  const handleChangeMenu = (action: string): void => {
    router.push(action);
  };

  return (
    <>
      {dialogContext}
      <DynamicMenu
        isArrow={false}
        itemActive=""
        items={menuData}
        label={
          <div className="w-full truncate pr-1.5 text-sm sm:w-[100px]">
            {username}
          </div>
        }
        onChange={handleChangeMenu}
      />
    </>
  );
};

export default UserMenu;
