import { joiResolver } from '@hookform/resolvers/joi';
import {
  CloseOutlined,
  LockStarOutlined,
  UserOutlined,
} from '@packages/icons-react';
import Joi from 'joi';
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { signIn } from 'next-auth/react';
import { setCookie } from 'nookies';
import dayjs from 'dayjs';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { Input } from '~components/input';
import SuggestedInDialogWidget from '~components/widgets/suggested-in-dialog-widget';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';

interface SignInFormProps {
  username: string;
  password: string;
}

const SignInContainer: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const router = useRouter();
  const { t } = useTranslation(['forms', 'buttons', 'etc']);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const schemaErrorsSignInForm = Joi.object({
    username: Joi.string()
      .required()
      .messages({
        'any.required': t('forms:error-username-empty'),
        'string.empty': t('forms:error-username-empty'),
      }),
    password: Joi.string().messages({
      'any.required': t('forms:error-password-empty'),
      'string.empty': t('forms:error-password-empty'),
    }),
  }).unknown(true);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormProps>({
    resolver: joiResolver(schemaErrorsSignInForm),
  });

  const handleRequestLogin: SubmitHandler<SignInFormProps> = async (values) => {
    let response;
    const { username, password } = values;
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      const encryptPassword = encrypt(password);
      response = await axios.post(`/api/authentication/login`, {
        username: username.trim().toLowerCase(),
        password: encryptPassword,
        token,
      });
    } catch (error) {
      HttpErrorHandler(error);
    }
    if (response) {
      const jwtDecoded = jwt.decode(response.data.accessToken);
      setCookie(undefined, 'access_token', response.data.accessToken, {
        maxAge: dayjs(jwtDecoded.exp * 1000).diff(dayjs(), 's'),
        path: '/',
      });
      await signIn('credentials', {
        accessToken: response.data.accessToken,
      });
    }
  };

  return (
    <div className="bg-secondary relative h-screen w-screen p-6 sm:h-full sm:max-w-[500px] sm:rounded-3xl sm:border-4 sm:border-white">
      <div
        aria-hidden
        className="absolute right-4 top-4 flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full bg-white text-sm text-black"
        onClick={onClose}
        role="button"
      >
        <CloseOutlined />
      </div>
      <SuggestedInDialogWidget />
      <form
        className="flex w-full flex-col gap-3"
        onSubmit={handleSubmit(handleRequestLogin)}
      >
        <Input
          {...register('username')}
          errors={
            errors.username?.message ? [errors.username.message] : undefined
          }
          placeholder={t('forms:place-holder-username')}
          prefix={<UserOutlined />}
        />
        <Input
          {...register('password')}
          errors={
            errors.password?.message ? [errors.password.message] : undefined
          }
          placeholder={t('forms:place-holder-password')}
          prefix={<LockStarOutlined />}
          type="password"
        />
        <p className="text-yellow text-center text-sm">{t('etc:login-note')}</p>
        <Button loading={isSubmitting} type="submit">
          {t('buttons:btn-login')}
        </Button>
      </form>
      <Button
        block
        color={ButtonColors.Default}
        onClick={() => {
          router.push(PageUrls.ForgotPassword);
          onClose();
        }}
      >
        {t('buttons:btn-forgot-password')}
      </Button>
    </div>
  );
};

export default SignInContainer;
