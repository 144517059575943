import { cn } from 'class-merge';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, type FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';
import { ClientSideRender } from '~components/layouts/client-side-render';

interface ProviderCardInterface {
  logo: string;
  title: string;
  image: string;
  type: string;
  className?: string;
  onOpenGame: () => void;
}

const CustomImageLogo: FC<{
  logo: string;
}> = ({ logo }) => {
  const [image, setImage] = useState<string>(`${S3_BUCKET_IMAGES}/logo.webp`);

  useEffect(() => {
    setImage(logo);
  }, [logo]);

  return (
    <Image
      alt="logo"
      className="absolute right-1.5 top-1.5"
      draggable={false}
      height={32}
      src={image}
      unoptimized
      width={32}
    />
  );
};

const CustomImageHero: FC<{
  hero: string;
}> = ({ hero }) => {
  const [isError, setIsError] = useState<boolean>(false);

  if (isError) {
    return;
  }

  return (
    <Image
      alt="hero"
      className="absolute left-0 top-0 max-h-[95px] max-w-[100px] object-contain"
      draggable={false}
      fill
      onError={() => {
        setIsError(true);
      }}
      priority
      src={hero}
      unoptimized
    />
  );
};

const ProviderCard: FC<ProviderCardInterface> = ({
  image,
  logo,
  title = '???',
  className,
  onOpenGame,
}) => {
  const { t } = useTranslation('common');

  return (
    <button
      className={cn(
        'relative h-[120px] w-full max-w-[124px] overflow-hidden rounded-2xl',
        'transform transition-all duration-300 hover:scale-105',
        'from-gradient-top-provider-card to-gradient-bottom-provider-card bg-gradient-to-b',
        className,
      )}
      onClick={onOpenGame}
    >
      <ClientSideRender>
        <CustomImageHero hero={image} />
        <CustomImageLogo logo={logo} />
      </ClientSideRender>
      <div className="bg-light absolute bottom-0 left-0 flex h-6 w-full items-center justify-center text-center">
        <div className="w-[90%] truncate text-xs capitalize">
          {title || t('not-found-provider')}
        </div>
      </div>
    </button>
  );
};
export default ProviderCard;
