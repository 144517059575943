import type { FC } from 'react';
import i18n from '~i18n';

const LoadingText: FC = () => {
  const t = i18n.t;
  return (
    <div className="text-color animate-pulse text-center">
      {t('etc:wait-text')}...
    </div>
  );
};
export default LoadingText;
