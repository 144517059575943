import type {
  GameFeatureListsResApi,
  GameProviderInterface,
  ProvidersInterface,
} from 'types/provider';
import type { Method } from 'axios';
import type { Config, Return } from './use-request';
import useRequest from './use-request';
import type { ProviderEnum } from '~constants/menus';
import {
  S3_MEDIA_IMAGE,
  type CurrencyEnum,
  S3_BUCKET_IMAGES,
} from '~constants/etc';

export const useGameFeature = (
  currency: string,
  config?: Config<GameFeatureListsResApi[]>,
) => {
  return useRequest(
    {
      method: 'GET' as Method,
      url: '/providers/game-features',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useProviders = (
  params: {
    search?: string;
    ids?: string;
    categories?: string;
    type: ProviderEnum;
    currency: CurrencyEnum | string;
  },
  config?: Config<ProvidersInterface[]>,
): Return<ProvidersInterface[], unknown> => {
  const providers = useRequest<ProvidersInterface[]>(
    {
      url: '/providers',
      params,
    },
    {
      ...config,
      revalidateOnFocus: false,
      refreshInterval: 0,
      revalidateOnMount: true,
    },
  );
  if (!Array.isArray(providers.data)) {
    return providers;
  }
  const providerWithImages = providers.data.map((provider) => ({
    ...provider,
    logo: `${S3_MEDIA_IMAGE}/${provider.id}/logo/${provider.id}-logo.png`,
    horizontalLogo: `${S3_MEDIA_IMAGE}/${provider.id}/logo/${provider.id}-horizontal-logo.png`,
    hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
  }));
  return {
    ...providers,
    data: providerWithImages,
  };
};

export const useGameWithProviders = (
  params: {
    search?: string;
    tags?: string;
    providers?: string;
    type: ProviderEnum;
    currency: CurrencyEnum | string;
  },
  config?: Config<GameProviderInterface[]>,
): Return<any, unknown> => {
  const providers = useRequest<GameProviderInterface[]>(
    {
      url: '/providers/games',
      params,
    },
    {
      ...config,
      revalidateOnFocus: false,
      refreshInterval: 0,
      revalidateOnMount: true,
    },
  );
  if (!Array.isArray(providers.data)) {
    return providers;
  }
  const providerWithImages = providers.data.map((provider) => ({
    ...provider,
    hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.gameCode}.webp`,
    logo: `${S3_MEDIA_IMAGE}/${provider.provider}/logo/${provider.provider}-logo.png`,
    thumbnail: `${S3_MEDIA_IMAGE}/${provider.provider}/games/${provider.gameCode}/thumbnail.webp`,
  }));
  return {
    ...providers,
    data: providerWithImages,
  };
};
