import type { FC } from 'react';
import { useMemo } from 'react';
import { CloseOutlined } from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import type { AllowProviderType } from '../../types/promotion';
import type { AllowedGameProvidersType } from './allowed-provider-container';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { DEFAULT_CURRENCY, ProviderTypeEnum } from '~constants/etc';
import { useAbilities } from '~contexts/abilities';
import TagsWidget from '~components/widgets/tag-widget';
import { useProviders } from '~hooks/use-providers';
import { ProviderEnum } from '~constants/menus';

interface AllowedProviderSportListsProps {
  providers: AllowedGameProvidersType[];
}

const AllowedProviderSportLists: FC<AllowedProviderSportListsProps> = (
  props,
) => {
  const { providers } = props;
  const { t } = useTranslation(['providers', 'financials']);
  const { ability } = useAbilities();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: sports } = useProviders(
    {
      type: ProviderEnum.SPORTSBOOK,
      currency,
    },
    { suspense: true },
  );
  const providerLists = useMemo<AllowProviderType[]>(() => {
    let sportAllowProviders: AllowProviderType[] = [];
    sports
      ? sports?.forEach((sport) => {
          const allow = ability?.can(
            'read',
            ProviderTypeEnum.Sportsbook,
            sport.id,
          );
          if (!allow) {
            return;
          }
          sportAllowProviders = [
            ...sportAllowProviders,
            {
              providerName: t(`providers:${sport.id}` as any),
              logo: sport.logo,
            },
          ];
        })
      : null;
    if (!sportAllowProviders.length) {
      return [
        {
          providerName: t('financials:not-allowed-text'),
          logo: (
            <div className="flex h-[16px] w-[16px] items-center justify-center rounded bg-red-700 text-[8px] text-white">
              <CloseOutlined />
            </div>
          ),
        },
      ];
    }
    return sportAllowProviders;
  }, [providers, sports]);

  return (
    <div className="flex flex-wrap gap-2">
      {providerLists.map((provider, i) => (
        <TagsWidget key={i} logo={provider.logo} text={provider.providerName} />
      ))}
    </div>
  );
};

export default AllowedProviderSportLists;
