'use client';

import Image from 'next/image';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { cn } from 'class-merge';
import { ArrowLeftOutlined } from '@packages/icons-react';
import { usePathname } from 'next/navigation';
import useIsMobile from '~libs/use-is-mobile';
import useAuth from '~hooks/use-auth';
import AuthorizedNavigation from '~components/layouts/navbar/authorized-navigation';
import CustomerService from '~components/layouts/navbar/customer-service';
import UnauthorizedNavigation from '~components/layouts/navbar/unauthorized-navigation';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import { DEFAULT_LANG, LanguageEnum, S3_BUCKET_IMAGES } from '~constants/etc';
import ChangeLanguageMenu from '~components/widgets/change-language';
import { useRouter } from '~hooks/use-router';
import PageUrls from '~constants/page-urls';

interface TopBarProps {
  agentInfo: AgentInfoResponseInterface;
  region: string;
  setMenuActive: (active: boolean) => void;
  menuActive: boolean;
}

const TopBar: FC<TopBarProps> = ({ agentInfo, region }) => {
  const router = useRouter();
  const pathname = usePathname();
  const pathnameSplits = pathname.split('/');
  const isHomepage = pathnameSplits.length === 2;
  const isMobile = useIsMobile();
  const { isLoggedIn } = useAuth();
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);
  const isLanguageThailand = DEFAULT_LANG === LanguageEnum.TH;

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!initialRenderComplete) return null;

  if (isMobile) {
    return (
      <div className="min-h-[60px] w-full px-4">
        <div className="relative mt-2 flex select-none grid-cols-3 items-center justify-center">
          {!isHomepage && (
            <div
              aria-hidden
              className="bg-secondary absolute left-0 flex h-[40px] w-[40px] items-center justify-center rounded-lg"
              onClick={() => router.back()}
              role="button"
            >
              <ArrowLeftOutlined />
            </div>
          )}
          <Image
            alt="logo"
            className="mx-auto"
            draggable={false}
            height={60}
            onClick={() => {
              router.push(PageUrls.Home);
            }}
            priority
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            width={90}
          />
          {!isLanguageThailand && (
            <div
              className={cn(
                'absolute right-0 text-right',
                isLoggedIn ? 'hidden' : 'block',
              )}
            >
              <ChangeLanguageMenu />
            </div>
          )}
        </div>
        <div className="h-18 visible w-full transform space-y-2 transition-all duration-300">
          {isLoggedIn ? (
            <AuthorizedNavigation />
          ) : (
            <UnauthorizedNavigation
              agentInfo={agentInfo}
              className="my-2"
              region={region}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex h-14 max-w-[1920px] items-center justify-between px-[4%]">
      <CustomerService />
      <div className="flex items-center gap-2">
        {isLoggedIn ? (
          <AuthorizedNavigation />
        ) : (
          <>
            <UnauthorizedNavigation agentInfo={agentInfo} region={region} />
            {!isLanguageThailand && (
              <div className="text-right">
                <ChangeLanguageMenu />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
