import { ArrowLeftOutlined } from '@packages/icons-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { AllowedProviderType } from '~containers/promotions/allowed-provider-container';

const AllowedProviderWidget: FC<{
  providers: AllowedProviderType[];
  withActivePromotion?: boolean;
  onClose: () => void;
}> = ({ providers, withActivePromotion = false, onClose }) => {
  const { t } = useTranslation('financials');

  return (
    <div className="bg-default min-h-screen w-screen gap-4 rounded-none p-4 font-normal sm:h-full sm:min-h-fit sm:w-full sm:rounded-3xl">
      <div className="flex items-center gap-4">
        <ArrowLeftOutlined className="cursor-pointer" onClick={onClose} />
        <div className="font-semibold">
          <p className="text-textColor-color-primary">
            {t('check-list-game-text')}
          </p>
          <p className="text-color-secondary text-sm">
            {t('allowed-dialog-description-text')}
          </p>
        </div>
      </div>
      {withActivePromotion ? (
        <p className="text-danger mt-4 text-sm">
          ***มีข้อจำกัดการเล่นเนื่องจากท่านทำการรับโปรโมชั่น
        </p>
      ) : null}
      <div className="xs:h-[700px] mt-4 flex h-[540px] flex-col gap-4 overflow-y-scroll sm:h-full">
        {providers.map((provider, i) => (
          <div
            className="bg-dark h-contain flex w-full flex-col gap-2 rounded-lg p-4"
            key={i}
          >
            <p>{provider.title}</p>
            {provider.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllowedProviderWidget;
